import Card from '../Card'

import ViewMixin from '../../js/viewMixin'
import ComponentMixin from '../../js/componentMixin.js'

import { openPdf } from '../../helpers'

export default {
  name: 'ReportAdditionalItem',
  mixins: [
    ViewMixin,
    ComponentMixin
  ],
  components: {
    Card
  },
  props: {
    content: {}
  },
  mounted () {
  },
  methods: {
    openPdf (file, windowName) {
      openPdf(file, windowName)
    },
    getMonthtext (monthNumber) {
      // convert month number to text
      const date = new Date(2000, monthNumber, 1)
      const month = date.toLocaleString('default', { month: 'long' })
      return this.$t(month)
    },
    convertPrice (price) {
      // add space every 3 digits
      return Math.floor(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
