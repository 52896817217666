import createHTMLMapMarker from '../../js/HTMLMapMarker'
import PlaneMarker from '../../assets/planeMarker.png'
import PARAMS from './mapStyle.json'

import FromTo from '../../components/FromTo'
import vSelect from 'vue-select'
import componentMixin from '../../js/componentMixin'
import { mapGetters } from 'vuex'
import moment from 'moment'

const google = window.google

export default {
  name: 'map',
  mixin: [componentMixin],
  components: {
    vSelect,
    FromTo
  },
  data: () => {
    return {
      singleCities: [],
      options: []
    }
  },
  watch: {
    from: function (newVal, oldVal) {
      // watch it
      this.initMap()
      this.drawPaths()
      this.setMarkers()
    },
    to: function (newVal, oldVal) {
      // watch it
      this.initMap()
      this.drawPaths()
      this.setMarkers()
    },
    missions () {
      this.initMap()
      this.drawPaths()
      this.setMarkers()
    }
  },
  computed: {
    ...mapGetters('missions', {
      missions: 'missions'
    }),
    ...mapGetters('datepicker', {
      from: 'from',
      to: 'to'
    }),
    intineraries () {
      this.singleCities = []
      const fromMilli = moment(this.from, 'MMM YYYY').valueOf()
      const toMilli = moment(this.to, 'MMM YYYY').endOf('month').valueOf()
      // console.log('this.missions', this.missions)
      // console.log('fromMilli', fromMilli)
      // console.log('toMilli', toMilli)
      const intineraries = this.missions.reduce((acc, current) => {
        const departure = moment(current.date_start).valueOf()
        const arrival = moment(current.date_end).valueOf()
        if (departure <= toMilli && arrival >= fromMilli) {
          acc.push(...current.legs)
          current.legs.forEach(leg => {
            if (!this.singleCities.some(e => e.name === leg.to.name)) {
              this.singleCities.push(leg.to)
            }
          })
        }
        return acc
      }, [])
      return intineraries
    }
  },
  mounted () {
    this.initMap()
    this.drawPaths()
    this.intineraries.map(el => {
      if (!this.singleCities.some(e => e.name === el.from.name)) {
        this.singleCities.push(el.from)
      }
      if (!this.singleCities.some(e => e.name === el.to.name)) {
        this.singleCities.push(el.to)
      }
    })
    this.setMarkers()
  },
  methods: {
    initMap () {
      // const that = this
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 0, lng: 0 },
        zoom: 1,
        minZoom: 0,
        maxZoom: 5,
        streetViewControl: false,
        disableDefaultUI: true,
        zoomControl: false,
        styles: PARAMS,
        backgroundColor: '#061A30'
      })
      // const customMapType = new google.maps.ImageMapType({
      //   getTileUrl: function (coord, zoom) {
      //     const normalizedCoord = that.getNormalizedCoord(coord, zoom)
      //     if (!normalizedCoord) {
      //       return ''
      //     }
      //     return require(`../../assets/custom/tile_${zoom}_${normalizedCoord.x}-${normalizedCoord.y}.png`)
      //   },

      //   tileSize: new google.maps.Size(256, 256),
      //   maxZoom: 3,
      //   minZoom: 0,
      //   // radius: 1738000,
      //   radius: 6371000,
      //   name: 'custom'
      // })

      // this.map.mapTypes.set('custom', customMapType)
      // this.map.setMapTypeId('custom')
    },
    getNormalizedCoord (coord, zoom) {
      const y = coord.y
      let x = coord.x
      // tile range in one direction range is dependent on zoom level
      // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
      const tileRange = 1 << zoom

      // don't repeat across y-axis (vertically)
      if (y < 0 || y >= tileRange) {
        return null
      }

      // repeat across x-axis
      if (x < 0 || x >= tileRange) {
        x = ((x % tileRange) + tileRange) % tileRange
      }
      return { x: x, y: y }
    },
    drawPaths () {
      this.intineraries.map(el => {
        const flightPlanCoordinates = el.to.isCurrent
          ? [
            el.from,
            new google.maps.LatLng(el.to.currentLat, el.to.currentLng)
          ]
          : [el.from, el.to]
        const flightPath = new google.maps.Polyline({
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: '#559DF9',
          strokeOpacity: 1.0,
          strokeWeight: 2.5
        })
        if (el.isCurrent) {
          const path = [
            new google.maps.LatLng(el.to.currentLat, el.to.currentLng),
            new google.maps.LatLng(el.from.lat, el.from.lng)
          ]
          el.to.heading = google.maps.geometry.spherical.computeHeading(
            path[0],
            path[1]
          )
        }
        flightPath.setMap(this.map)
        // flightPath.getAt()
      })
    },
    setMarkers () {
      this.singleCities.map(el => {
        if (el.name !== 'current') {
          createHTMLMapMarker({
            latlng: new google.maps.LatLng(el.lat, el.lng),
            map: this.map,
            html: '<div class="map-block-marker"></div>'
          })
        } else {
        }
      })
      this.intineraries.map(el => {
        if (el.to.isCurrent) {
          createHTMLMapMarker({
            latlng: new google.maps.LatLng(el.to.currentLat, el.to.currentLng),
            map: this.map,
            html: `<div class='map-block-marker__current' style='transform: translate(-59%, -50%) rotate(${el.heading +
              90}deg'><img src='${PlaneMarker}' />  </div>`
          })
        }
      })
    }
  }
}
