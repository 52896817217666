<template>
  <div id="nav" class="nav">
    <div class="nav-container">
      <SwitchPlane />

      <router-link class="nav-links nav-links-logo " to="/"><img :src="logo" alt=""></router-link>
      <div class="nav-links-block">

        <router-link class="nav-links" to="/">
          <span class="icon icon-plane"></span>
          <span class="title">{{ $t('my-aircraft') }}</span>
        </router-link>
        <router-link class="nav-links" to="/financial-details">
          <span class="icon icon-recap"></span>
          <span class="title">{{ $t('my-financial-details') }}</span>
        </router-link>
        <!-- <router-link class="nav-links" to="/flights-details"><span class="icon icon-WarehouseIcon"></span><span class="title">{{ $t('my-flights-details') }}</span></router-link> -->
        <!-- <router-link class="nav-links" to="/reports">
          <span class="icon icon-Accounting"></span>
          <span class="title">{{ $t('my-reports') }}</span>
        </router-link> -->

        <SwitchPlane />

        <SwitchLang />

        <router-link class="nav-links settings" to="/settings"><span class="icon icon-settings"></span></router-link>
        <a class="nav-links logout" @click="_logout">{{ $t('logout') }}</a>
      </div>

      <SwitchLang />
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.less" lang="less"></style>
